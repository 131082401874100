module.exports = {
  api_host: process.env.REACT_APP_ELA_API_HOST,
  api_email: process.env.REACT_APP_ELA_API_EMAIL,
  api_password: process.env.REACT_APP_ELA_API_PASSWORD,
  organization_id: process.env.REACT_APP_ORGANIZATION,

  gevme_host: env => {
    var host = "";
    switch (env) {
      case "production":
        host = "https://www.gevme.com/";
        break;
      case "staging":
        host = "https://pre-prod.gev.me/";
        break;
      case "dev":
        host = "https://dev.gev.me/";
        break;
      default:
        host = "http://localhost/";
        break;
    }
    return host;
  }
};