import "babel-polyfill";
import "react-app-polyfill/ie11";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from "react-redux";
import { store, persistor } from "./configureStore";
import { PersistGate } from "redux-persist/integration/react";

async function cleanupPersistence() {
  await persistor.purge();
};

if (window.location.pathname === '/logout') {
  cleanupPersistence();
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
