import { takeLatest, put, call, all, fork } from "redux-saga/effects";
import axios from "axios";
import apiConfig from "../config/api";
import { APP_LOGIN, setAppToken, setAppError } from "../actions";

// API call
export function requestAppLogin(data) {
  return axios.request({
    method: "post",
    url: apiConfig.api_host + "/auth/sign_in",
    data
  });
}


// Workers
export function* setAppLoginWorker(action) {
  try {
    const appLoginData = yield call(requestAppLogin, action.payload);
    
    if (appLoginData.data.token) {
      
      yield put(setAppToken(appLoginData.data));

    } else {
      yield put(setAppError("Failed to get access token from ELA API"));      
    }    
  } catch (e) {
    yield put(setAppError(e.message));
  }
}

// Watchers
export function* setAppLoginWatcher() {
  yield takeLatest(APP_LOGIN, setAppLoginWorker);
}

// Sagas
export default function* appSagas() {
  yield all([fork(setAppLoginWatcher)]);
}
