import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import app from "./appReducer";
import events from "./eventReducer";
import voyager from "./voyagerReducer";
import search from "./searchReducer";

const createReducers = history =>
  combineReducers({
    app,
    voyager,
    events,
    search,
    router: connectRouter(history)
  });

export default createReducers;
