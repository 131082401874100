const crypto = require("crypto");

const AES_METHOD = "aes-128-cbc";
const IV_LENGTH = 16; // For AES, this is always 16

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || "P6rYDukvbkuNBV4bSWZr5abK8dmO1K1e"; // Must be 256 bytes (32 characters)

const encrypt = text => {
  const secretIv = crypto.randomBytes(IV_LENGTH);
  const cryptKey = ENCRYPTION_KEY.substr(0, IV_LENGTH);
  const hash = crypto
    .createHash("sha256")
    .update(secretIv)
    .digest("hex");
  const iv = hash.substr(0, IV_LENGTH);
  const cipher = crypto.createCipheriv(AES_METHOD, Buffer.from(cryptKey), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return `${iv}$$${encrypted.toString("base64")}`;
};

const decrypt = encrypted => {
  const textParts = encrypted.split("$$");
  const iv = Buffer.from(textParts[0]);
  const cryptKey = ENCRYPTION_KEY.substr(0, IV_LENGTH);
  const encryptedText = Buffer.from(textParts[1], "base64");
  const decipher = crypto.createDecipheriv(
    AES_METHOD,
    Buffer.from(cryptKey),
    iv
  );
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};

module.exports = {
  encrypt,
  decrypt
};
