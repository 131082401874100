import {
  SEARCH_EVENTS,
  SET_SEARCH_EVENTS_RESULT,
  SET_SEARCH_EVENTS_ERROR
} from "./const";

/**
 * @param {string} payload
 */
export function searchEvents(payload) {
  return {
    type: SEARCH_EVENTS,
    payload
  };
}

/**
 * @param {string} payload
 */
export function setSearchEventsResults(payload) {
  return {
    type: SET_SEARCH_EVENTS_RESULT,
    payload
  };
}

/**
 * @param {string} payload
 */
export function setSearchEventsError(payload) {
  return {
    type: SET_SEARCH_EVENTS_ERROR,
    payload
  };
}