import { fork, all } from "redux-saga/effects";
import AppSaga from "./appSaga";
import VoyagerSaga from "./vogayerSaga";
import SearchSaga from "./searchSaga";

const sagas = [
  AppSaga,
  VoyagerSaga,
  SearchSaga
];

export default function* rootSaga() {
  const globalSagasForks = sagas.map(saga => fork(saga));
  yield all([...globalSagasForks]);
}
