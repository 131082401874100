import * as actionTypes from "../actions";

const initialState = {
  user: [],
  voyager_error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VOYAGER_SET_DETAILS:
      return { ...state, user: action.payload };

    case actionTypes.SET_VOYAGER_ERROR:
      return { ...state, voyager_error: action.payload };

    default:
      return state;
  }
};

export default reducer;
