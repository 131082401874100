import { takeLatest, put, call, all, fork, select } from "redux-saga/effects";
import apiConfig from "../config/api";
import {
  setVoyagerError,
  VOYAGER_LOGIN,
  setVoyagerDetails,
  setSearchEventsResults,
  setSearchEventsError,
} from "../actions";
import * as apiCall from "../utils/apiCall";
import lodash from "lodash";


export const getToken = state => state.app.ela.token;
export const getEvents = (state) => state.events.lists;
export const getSearches = (state) => state.search.lists;


// Workers
export function* setVoyagerLoginWorker(action) {
  try {
    const token = yield select(getToken);
    const loginData = yield call(apiCall.requestVoyagerLogin, action.payload, token);
    yield put(setVoyagerError(null));
    yield put(setSearchEventsError(null));
    yield put(setSearchEventsResults(null));
    if (loginData.data.profileStatus !== 'Active') {
      yield put(setVoyagerError("Voyager User account is inactive"));
    } else {
      yield put(setVoyagerDetails(loginData.data));
    }
    

    if (loginData.data) {
      try {
        const payload = {
          force_reload: true,
          is_searching: false,
          params: {
            filters: {
              private: true,
              published: true,
            },
            organization_id: apiConfig.organization_id,
          },
        };
        const { force_reload, is_searching, params } = payload;
        let searchResult = [];

        if (force_reload && !is_searching) {
          let eventsData = yield call(apiCall.requestEventsData, params, token);
          while (eventsData.data.success !== true) {
            eventsData = yield call(apiCall.requestEventsData, params, token);
          }
          
          if (lodash.size(eventsData.data.events) > 0) {
            searchResult = { ...eventsData.data.events };
          } else {
            yield put(setSearchEventsError("No events found"));
          }        
        }

        yield put(setSearchEventsResults(searchResult));

      } catch(e) {
        yield put(setSearchEventsError(e.message));
      }

    }
  } catch (e) {
    yield put(setVoyagerError("Failed to authenticate voyager account"));
  }
}

// Watchers
export function* setVoyagerLoginWatcher() {
  yield takeLatest(VOYAGER_LOGIN, setVoyagerLoginWorker);
}

// Sagas
export default function* voyagerSagas() {
  yield all([fork(setVoyagerLoginWatcher)]);
}
