import React, {useState, useEffect} from "react";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";

export const Spinner = props => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);
  
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: rgb(54, 215, 183);
  `;  
  return (
    <div className="sweet-loading">
      <GridLoader
        css={override}
        size={props.size}
        margin={props.margin}
        color={props.color ? props.color : "#123abc"}
        loading={loading}
      />
    </div>
  );
}