import * as actionTypes from "../actions";

const initialState = {
  lists: [],
  search_error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_EVENTS_RESULT:
      return { ...state, lists: action.payload };

    case actionTypes.setSearchEventsError:
      return { ...state, search_error: action.payload };

    default:
      return state;
  }
};

export default reducer;
