import axios from "axios";
import apiConfig from "../config/api";

export function requestVoyagerLogin(payload, token) {
  const options = {
    headers: { Authorization: "JWT " + token },
    data: { ...payload }
  };
  return axios({
    method: "POST",
    url: apiConfig.api_host + "/voyager/login",
    headers: options.headers,
    data: options.data
  });
}


export function requestEventsData(data, token) {
  const options = {
    headers: { Authorization: "JWT " + token, "Content-Type": "application/json" }
  }

  var queryString = Object.keys(data).map((key) => {
    if (key === "organization_id") {
      return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]); 
    } else if (key === "filters") {
      return Object.keys(data[key]).map((ckey) => {          
        return `filters[${ckey}]=` + data[key][ckey];
      }).join('&');
    }
  }).join('&');
  
  
  return axios.get(
    apiConfig.api_host + "/gevme/events?" + queryString,
    options
  );
}