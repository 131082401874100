import styled from "styled-components";
import mainBg from "../../assets/images/cefc-main-bg.png";

export const CefcStyle = styled.div.attrs({
  className: "cefc-page"
})`
  .main-container {
    height:460px;
    background-image: url(${mainBg});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }

  h1.banner-title {
    color: #fff;
  }

  .login-box {
    width: 380px;
    margin: 0 auto;
    text-align: left;
    max-height: 340px;
    padding-top: 30px;
    font-size: small;
    padding-bottom: 30px;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 30px;
  }

  .login-box .fa-user {
    color: #fff;
  }


  .login-box .form-label {
    color:#fff;
  }

  .browser-supported a {
    color: blue;
  }
  .browser-supported {
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    font-size: smaller;
    border-radius: 5px;
  }
  .top-desc {
    padding-top: 30px;
    color: #fff;
    height: 201px;
    width: 356px;
    margin: 0 auto;
    font-family: Lato;
    margin-top: 94px;
    margin-bottom: 54px;
  }

  .gallery-box {
    background-color: #fff;
    min-height: 400px;
    height:auto;
    text-align:center;
    padding-bottom: 30px;
  }

  .gallery-container {
    margin-top: 42px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

  }

  .cardItemBox {
    width: 310px;
    height: 310px;
    overflow: hidden;
    margin-top: 10px;
    margin-left: 35px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #ececec;
    border-radius: 20px;
    position: relative;
  }

  .cardItemBoxOverlay {
    width: 310px;
    height: 315px;
    background-color: rgb(0,137,130);
    background-color: rgba(0,137,130,0.61);
    border-radius: 10px;
    margin-top: -5px;
    position: absolute;
  }

  .cardItemBoxOverlay2 {
    width: 310px;
    height: 315px;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.50);
    border-radius: 10px;
    margin-top: -5px;
    position: absolute;
  }


  .cardItemBody {
    margin:5px;
    padding:5px;
    width: 96%;
    color: #fff;
    position:absolute;
  }

  .cardItemBody .eventType {
    display: block;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
  }

  .cardItemBody .eventTitle {
    display: block;
    position: relative;
    margin: 68px 13px 5px 23px;
    border-bottom: solid 2px;
    font-family: Lato;
    font-size: 17px;
    text-align: left;
    vertical-align: middle;
    font-weight: bold;
    padding-bottom:10px;
  }

  .cardItemBody .eventSched {
    display: block;
    text-align: left;
    margin-left: 23px;
    height: 19px;
    color: #FFFFFF;
    font-family: Lato;
    font-size: smaller;
    font-weight: bold;
    line-height: 19px;
    margin-top: 8px;
  }

  .cardItemBody .eventVenue {
    display: block;
    text-align: left;
    margin-left: 23px;
    height: 19px;
    color: #FFFFFF;
    font-family: Lato;
    font-size: smaller;
    font-weight: bold;
    line-height: 19px;
    margin-top: 8px;
  }  


  .cardItemBody .eventVenue .input-group-text,
  .cardItemBody .eventSched .input-group-text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 1px 0 0;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    text-align: center;
    white-space: nowrap;
    border-radius: .25rem;
    margin-right: 5px;
    background-color: transparent;
    border-color: transparent;
    color: #fff;    
  }

  .cardItemBody .eventLink {
    display: inline-block;
    color: #fff;
    background-color: #00C2C6;
    border-radius: 24px;
    text-decoration:none;
  }  

  .thumbnail {
    max-width:270px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;    
  }

  .ela-title{
    width: 100%;
    text-align: left;
    padding-left: 50px;
    padding-top: 30px;
    height: 36px;
    color: #007770;
    font-family: Lato;
    font-size: 30px;
    font-weight: 900;
    letter-spacing: 0.46px;
    line-height: 36px;
  }

  .ela-no-event {
    height: 36px;	
    max-width: 485px;	
    color: #B0B0B0;	
    font-family: Lato;
    font-size: 30px;	
    letter-spacing: 0.46px;	
    line-height: 36px;
    margin-left: 274px;
    margin-top: 54px;
    text-align: center;
    margin: 0 auto;
    margin-bottom:20px;
  }

  .login-box .btn-primary {
    background-color:transparent !important;
    border-color: transparent !important;
  }


  .ela-contact {
    min-height: 22px;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    text-align: left;
    padding: 20px;
    margin-left:50px;
    padding-bottom: 0px;
  }

  .ela-contact-list {
    list-style: none;
    text-align: left;    
    color: #fff;
    font-size: small;
    margin-left:60px;
    opacity:0.6;
    line-height: 1.8em;
  }

  .form-control:focus {
      border-color: #66afe9;
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
  }  
  .bootstrap-select .btn:focus {
    outline: none !important;
  }

  .login-box .dropdown-item {
    font-size: 15px;
    font-family: Lato;
  }

@media only screen and (max-width: 600px) {
  .ela-contact,
  .ela-contact-list {
    margin-left:0px !important;
  }
  
  .ela-title {
    padding-left:0px;
  }

  .cardItemBox {
    margin-left:0px;
  }
}
@media only screen and (min-width: 768px),
@media only screen and (min-width: 992px) {
  .main-container {
    width:auto;
    height:460px;
  }


}  
`;
