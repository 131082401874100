
import {
  APP_LOGIN,
  SET_APP_DATA,
  SET_APP_TOKEN,
  SET_APP_ERROR
} from './const';


/**
 * @param {string} payload
 */
export function appLogin(payload) {
  return {
    type: APP_LOGIN,
    payload
  }
}

/**
 * @param {string} payload
 */
export function setAppData(payload) {
  return {
    type: SET_APP_DATA,
    payload
  }
}

/**
 * @param {string} payload
 */
export function setAppToken(payload) {
  return {
    type: SET_APP_TOKEN,
    payload
  }
}




/**
 * @param {string} payload
 */
export function setAppError(payload) {
  return {
    type: SET_APP_ERROR,
    payload
  }
}
