import React, {useState} from 'react';
import { InputGroup, Button } from "react-bootstrap";
import * as moment from "moment";
import moment_timezone from "moment-timezone";
import apiConfig from "../config/api";
import FontAwesome from "react-fontawesome";
import crypto from "../utils/crypto";
import base64encode from "base64url";
import _ from "lodash";
import { cefc_ticket_rule } from "../utils/cefcRules";

export const CardItem = props => {
  const {
    name, id, slug, venue, begin, end, event_begin, event_end,
    landingPage: { banner_image }, promotions, tickets
  } = props.event[1];

  const [isSelected, setSelected] = useState(0);
  
  const banner = banner_image ? banner_image : "https://via.placeholder.com/180x100?text=Event";
  const style = { backgroundImage:  "url(" + banner + ")" };

  let eventTickets = {...tickets };

  let showTickets = Object.values(eventTickets).filter((ticket) => {
    return cefc_ticket_rule(ticket, props.event[1], props.user);
  });

  
  // Encrypt
  const eventData = JSON.stringify({
    event_id: id,
    user: {
      ...props.user,
    },
    tickets: { ...showTickets },
  });

  let ivtKey = crypto.encrypt(eventData);

  // Base64 Encode
  var encodedString = base64encode.encode(ivtKey);

  const eventUrl = slug => {
    let queryParams = slug + `?ivt_k=${encodedString}`;
    if (promotions && promotions.length > 0) {
      const promo = promotions.map(promo => ({ "code": promo.code, "status": promo.status })).find(promo => promo.status !== "Deleted" && promo.code === "ELAaccess");
      if (promo) {
        queryParams = slug + `?promo=${promo.code}&ivt_k=${encodedString}`;
      }        
    }
    return apiConfig.gevme_host(process.env.NODE_ENV) + queryParams;
  };

  const eventSched = (begin, end, event_begin, event_end) => {
    let sched = '';
    if (end) {
      sched =
        moment(new Date(event_begin)).format("DD") +
        " - " +
        moment(new Date(event_end)).format("DD-MMM YYYY") + " @ " +
        moment_timezone(new Date(begin)).tz("UTC").format("hh:mm A")
    } else {
        sched = 
        moment(new Date(event_begin)).format("DD MMM YYYY") + " @ " +
        moment_timezone(new Date(begin)).tz("UTC").format("hh:mm A")
    }
    return sched;
  }

  return (
    <div className={["cardItemBox"].join(" ")} style={style} 
      onMouseOver={() => setSelected(1)}
      onMouseLeave={() => setSelected(0)}
    >
      {!isSelected ?
        <div className="cardItemBoxOverlay"></div>
      :
        <div className="cardItemBoxOverlay2"></div>
      }
      <div className={["cardItemBody"].join(" ")}>
        {props.event[1].private ? (
          <i className="eventType pull-right">Private</i>
        ) : (
          <i className="eventType pull-right">Public</i>
        )}
        <h3 className="eventTitle">{name}</h3>
        <h3 className="eventSched">
          <InputGroup>
            {moment(new Date(event_begin)).format("DD MMM YYYY")}
          </InputGroup>
        </h3>
        <br />
        <h3 className="eventSched">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesome name="clock-o" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            {eventSched(begin, end, event_begin, event_end)}
          </InputGroup>
        </h3>
        <h3 className="eventVenue">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesome name="map-marker" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            {venue}
          </InputGroup>
        </h3>
          <Button
            variant="link"
            className="pull-right eventLink"
            target="_blank"
            href={eventUrl(slug)}
          >
            Register
          </Button>
      </div>
    </div>
  );
}