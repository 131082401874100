import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import history from './history';
import { CEFCPage } from './pages/cefc';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class App extends Component {
  routes = [
    {
      path: '/',
      exact: true,
      main: () => <CEFCPage />
    },
    {
      path: '/logout',
      exact: true,
      main: () => window.location.href = "/"
    }    
  ];

  render() {
    return (
      <ConnectedRouter history={history}>
        <Switch>
          {this.routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={route.main}
            />
          ))}
        </Switch>
      </ConnectedRouter>
    );
  }
}