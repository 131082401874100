import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { __RouterContext } from 'react-router';
import useForceUpdate from "use-force-update";
import Helmet from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import { CefcStyle } from "./cefcStyles";
import { appLogin, searchEvents } from '../../actions';
import apiConfig from '../../config/api';
import _ from "lodash";
import { LoginForm } from "./loginForm";
import { Gallery, Spinner } from "../../components";
import { createGlobalStyle } from 'styled-components';
import FontAwesome from "react-fontawesome";
import { cefc_event_rules } from "../../utils/cefcRules";

export const CEFCPage =  () => {
  const dispatch = useDispatch();
  const { app, events, voyager, search } = useSelector(state => ({
    app: state.app,
    events: state.events,
    voyager: state.voyager,
    search: state.search
  }));
  const forceUpdate = useForceUpdate();
  const routerContext = useContext(__RouterContext);  

  useEffect(() => {
    if (!app.ela.token) {
      dispatch(
        appLogin({
          email: apiConfig.api_email,
          password: apiConfig.api_password,
        })
      );
    }
    
    let payload = {
      force_reload: true,
      is_searching: false,
      params: {
        filters: {
          published: true,
        },
        organization_id: apiConfig.organization_id,
      },
    };
    if (voyager.user.success) {
      payload.params.filters = {
        private: true,
        published: true,
      };
    }
    if (app.ela.token) {
      dispatch(searchEvents(payload));
    }

    routerContext.history.listen(forceUpdate);

  }, [app.ela.token, voyager.user, dispatch, routerContext, forceUpdate ]);

  const GlobalStyle = createGlobalStyle`
      @import url('https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext');
      body {
        font-family: 'Lato', Latin, Bold, Regular !important;
        paddingLeft: 0 !important;
        paddingRight: 0 !important;
        background-color: #00B5AE !important;
      }
  `
    let lists = !_.isEmpty(search.lists) ? search.lists : events.lists;
    let allEvents = Object.values(lists).filter(event => event.published && (!event.publish_begin || event.publish_begin < Date.now()));

    let showEvents = Object.values(allEvents).filter(event => { 
      return cefc_event_rules(event, voyager.user);
    });
    showEvents = _.orderBy(showEvents, ["private"], ["asc"]);

    const _publicEvents = showEvents.filter(event => !event.private);
    const _privateEvents = showEvents.filter((event) => event.private);
    
    const publicEvents = _.orderBy(_publicEvents, ['event_begin', 'name'], ['asc']);
    const privateEvents = _.orderBy(_privateEvents, ['event_begin', 'name'], ['asc']);

    return (
      <CefcStyle>
        <GlobalStyle />
        <Container fluid={true} className={["main-container", "text-center"]}>
          <Helmet title="Covenant EFC" />
          <Row>
            <Col className={["mx-auto", "xs-12"]}>
              <div className="top-desc">
                <h1 className="banner-title">Covenant EFC</h1>
                <p>Authentic Discipleship</p>
                <p>Intentional Disciple-making</p>
                <p>of A Certain Kind</p>
              </div>
              <LoginForm />
            </Col>
          </Row>
          <Row>
            <Col className="gallery-box">
              <h3 className="ela-title">Events</h3>
              {_.size(publicEvents) > 0 ? (
                <Gallery
                  EVENTS={{ lists: { ...publicEvents } }}
                  USER={{ ...voyager.user }}
                />
              ) : (
                <>
                  <br />
                  <br />
                  <p>Stay tuned for upcoming events</p>
                </>
              )}
              <br />
            </Col>
          </Row>
          {voyager.user.success ? (
            <Row>
              <Col className="gallery-box">
                <h3 className="ela-title">Private Events</h3>
                {_.size(lists) > 0 ? (
                  <Gallery
                    EVENTS={{ lists: { ...privateEvents } }}
                    USER={{ ...voyager.user }}
                  />
                ) : (
                  <>
                    <br />
                    <br />
                    <Spinner
                      loading={true}
                      size={15}
                      marging={2}
                      color={"rgb(54, 215, 183);"}
                    />
                  </>
                )}
                <br />
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <h3 className="ela-contact">Contact</h3>
              <ul className="ela-contact-list">
                <li>
                  <FontAwesome name="map-marker" /> ACKCentre,2 Kallang Avenue,
                  CT Hub #10-26,Singapore 339407
                </li>
                {/*<li>
                  <FontAwesome name="phone" /> (65) 6892 6811
                </li>*/}
                <li>
                  <FontAwesome name="envelope" /> bookevents@cefc.org.sg
                </li>
                <li>
                  <FontAwesome name="history" /> Monday, Wednesday - Friday,
                  8:30am to 6:00pm
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </CefcStyle>
    );
}