import * as actionTypes from "../actions";

const initialState = {
  lists: [],
  events_error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EVENTS:
      return { ...state, lists: action.payload };

    case actionTypes.SET_EVENTS_ERROR:
      return { ...state, events_error: action.payload };

    default:
      return state;
  }
};

export default reducer;
