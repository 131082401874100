import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Popover, Overlay, Form, Button, InputGroup, Dropdown, ButtonToolbar, DropdownButton } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import * as yup from "yup";
import { Formik } from "formik";
import { voyagerLogin } from "../../actions";
import voyagerConfig from "../../config/voyager";
import  "./cefc.css";


export const LoginForm = () => {
  const dispatch = useDispatch();
  const { voyager } = useSelector(state => ({
    voyager: state.voyager
  }));
  const [ show, setShow ] = useState(false);
  const [ target, setTarget ] = useState(null);
  
  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };    

  const schema = yup.object({
    loginid: yup.string().required(),
    password: yup.string().required()
  });

  const _handleLoginForm = async form => {
    await dispatch(voyagerLogin(form));
  };

  const { user: { givenName, surname } } = voyager

  return (      
    <div className="login-box pull-right">
        {!voyager.user.success ?
        <>
            <ButtonToolbar>
              <Button onClick={ (e) => handleClick(e) }>
                <FontAwesome name="user" />{" "}Login
              </Button>

              <Overlay
                show={show}
                target={target}
                placement="bottom"
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Content>
                    

        <Formik
          validationSchema={schema}
          onSubmit={_handleLoginForm}
          initialValues={{ loginid: "", password: "" }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            isValid,
            errors
          }) => (
          <Form noValidate onSubmit={handleSubmit}>

          <Form.Group controlId="formLoginId">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FontAwesome name="user" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control 
                type="text" 
                placeholder="Enter Login Id"
                name="loginid"
                value={values.loginid}
                onChange={handleChange}
                isInvalid={!!errors.loginid}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formPassword">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FontAwesome name="lock" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control 
                required
                type="password"
                placeholder="Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
              />
            </InputGroup>
            <Button variant="link" href={voyagerConfig.forgot_pwd_url} className="forgot-pwd-link" target="_blank">Forgot Password</Button>
          </Form.Group>
          

          <Form.Text className="text-danger font-weight-bold">
            <ul className="login-err-msgs">
              {errors.loginid ? <li>{errors.loginid}</li> : null }
              {errors.password ? <li>{errors.password}</li> : null}
              {voyager.voyager_error !== null ? <li>{voyager.voyager_error}</li> : null }
            </ul>
          </Form.Text>
           
          <Button type="submit" className="btn pull-right login-btn">
            Login
          </Button>
        </Form>
            )}
        </Formik>         


                </Popover.Content>
                </Popover>
              </Overlay>
            </ButtonToolbar>
        </>
        : 
        <>
        <ButtonToolbar className="pull-right">
          <FontAwesome name="user" size="2x" />
          <DropdownButton
              size="sm"              
              title={givenName + " " + surname }
          >              
            <Dropdown.Item href={voyagerConfig.forgot_pwd_url} target="_blank">Change Password</Dropdown.Item>
            <Dropdown.Item href="/logout">Logout</Dropdown.Item>
          </DropdownButton>
        </ButtonToolbar>

        </>
        }
      </div>
  );
}