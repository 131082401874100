import {
  SET_VOYAGER_ERROR,
  SET_VOYAGER_MESSAGE,
  SET_VOYAGER_PROCESSING,
  VOYAGER_LOGIN,
  VOYAGER_SET_DETAILS
} from "./const";

/**
 * @param {string} payload
 */
export function voyagerLogin(payload) {
  return {
    type: VOYAGER_LOGIN,
    payload
  };
}


/**
 * @param {string} payload
 */
export function setVoyagerDetails(payload) {
  return {
    type: VOYAGER_SET_DETAILS,
    payload
  };
}

/**
 * @param {string} payload
 */
export function setVoyagerError(payload) {
  return {
    type: SET_VOYAGER_ERROR,
    payload
  };
}

/**
 * @param {string} payload
 */
export function setVoyagerMessage(payload) {
  return {
    type: SET_VOYAGER_MESSAGE,
    payload
  };
}

/**
 * @param {boolean} payload
 */
export function setVoyagerProcessing(payload) {
  return {
    type: SET_VOYAGER_PROCESSING,
    payload
  };
}
