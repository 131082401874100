export const APP_LOGIN = "@ELA/app_login";
export const SET_APP_TOKEN = "@ELA/set_app_token";
export const SET_APP_DATA = "@ELA/set_app_data";
export const SET_APP_ERROR = "@ELA/set_app_error";

export const SET_EVENTS = "@ELA/set_events";
export const SET_EVENTS_ERROR = "@ELA/set_events_error";

export const VOYAGER_LOGIN = "@ELA/voyager_login";
export const VOYAGER_SET_DETAILS = "@ELA/set_voyager_details";
export const SET_VOYAGER_ERROR = "@ELA/set_voyager_error";
export const SET_VOYAGER_MESSAGE = "@ELA/set_voyager_message";
export const SET_VOYAGER_PROCESSING = "@ELA/set_voyager_message";


export const SEARCH_EVENTS = "@ELA/search_events";
export const SET_SEARCH_EVENTS_RESULT = "@ELA/set_search_events_result";
export const SET_SEARCH_EVENTS_ERROR = "@ELA/set_search_events_error";