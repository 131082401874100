import React from "react";
import {CardItem} from '../components';

export const Gallery = props => {
  const { EVENTS, USER } = props;    
  return(    
      <div className={["w-100", "gallery-container"].join(" ")}>
        {EVENTS && Object.entries(EVENTS.lists).map((event, idx) => <CardItem key={idx} event={event} user={USER} />)}
      </div>
  );
}