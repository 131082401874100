import { takeLatest, put, all, fork, select, call } from "redux-saga/effects";
import {
  SEARCH_EVENTS,
  setSearchEventsResults,
  setSearchEventsError
} from "../actions";
import lodash from "lodash";
import * as apiCall from "../utils/apiCall";


export const getToken = state => state.app.ela.token;
export const getEvents = state => state.events.lists;
export const getSearches = state => state.search.lists;



// Workers
export function* setSearchEventWorker(action) {    
    let searchResult = [];
    
    if (!lodash.isEmpty(action.payload)) {      
      const token = yield select(getToken);
      const { force_reload, is_searching, params} = action.payload;

      if (force_reload && !is_searching) {
        let eventsData = yield call(apiCall.requestEventsData, params, token);
        while (eventsData.data.success !== true) {
          eventsData = yield call(apiCall.requestEventsData, params, token);
        }
        
        if (lodash.size(eventsData.data.events) > 0) {
          searchResult = { ...eventsData.data.events };
        } else {
          yield put(setSearchEventsError("No events found"));
        }        
      }

      if (is_searching) {
        const eventLists = yield select(getEvents);
        const searchLists = { ...eventLists };
        const eventName = action.payload.toLowerCase();
        searchResult = lodash.filter(searchLists, (event) => {
          return lodash.includes(event.name.toLowerCase(), eventName);
        })
      }
    }

    yield put(setSearchEventsResults(searchResult));
}

// Watchers
export function* setSearchEventsWatcher() {
  yield takeLatest(SEARCH_EVENTS, setSearchEventWorker);
}

// Sagas
export default function* searchSagas() {
  yield all([fork(setSearchEventsWatcher)]);
}
