// CEFC RULE
// All public event are visible to both login and non-login
// All private events depends on visibility set
export const cefc_event_rules = (event, voyagerUser) => {
  if (!event.private && event !== undefined) {
    // public events
    return event;
  } else {
    //private events, check visibility
    if (voyagerUser.success) {
      const { membershipType, spouseOfLeader, roles } = voyagerUser;
      const userMembershipType = [membershipType];
      const userRoles = roles ? roles.map((role) => role.role) : [];
      const userTypes = roles ? roles.map((role) => role.type) : [];
      const userSubTypes = roles ? roles.map((role) => role.subtype) : [];

      if (!event.hasOwnProperty("meta") || event.meta === null) {
        return;
      } else {
        const {
          voyager_visibility,
          voyager_type,
          voyager_subtype,
          voyager_spouseofleader,
        } = event.meta.meta;

        // Rule: if user is a spouse of a leader and event allows spouse of leader return event
        if ((spouseOfLeader === "Y") && (voyager_spouseofleader === "Y")) {
          // console.log("Rule #0: if user is a spouse of a leader and event allows spouse of leader return event");
          return event;
        } else {
          // Rule #1: if event.subtype exist, Check both user.type and user.subtype
          if (
            voyager_type &&
            voyager_subtype &&
            voyager_type.length > 0 &&
            voyager_subtype.length > 0 &&
            voyager_subtype.some((r) => userSubTypes.indexOf(r) >= 0) &&
            voyager_type.some((r) => userTypes.indexOf(r) >= 0)
          ) {
            // console.log(
            //   "Rule #1: if event.subtype exist, Check both user.type and user.subtype"
            // );
            return event;
          }

          // Rule #2: If event.type is empty, check event.visibility (userMembershipType)
          if (
            voyager_type &&
            voyager_visibility &&
            // voyager_subtype &&
            voyager_type.length === 0 &&
            // voyager_subtype.length === 0 &&
            voyager_visibility.some(
              (r) => userMembershipType.indexOf(r) >= 0
            )
          ) {
            // console.log(
            //   "Rule #2: If event.type is empty, check event.visibility (userMembershipType)"
            // );
            return event;
          }

          // Rule #3 : If event.type and event.visibility were not empty, check both
          if (
            voyager_type &&
            voyager_visibility &&
            voyager_type.length > 0 &&
            voyager_visibility.length > 0 &&
            voyager_visibility.some(
              (r) => userMembershipType.indexOf(r) >= 0
            ) &&
            voyager_type.some((r) => userTypes.indexOf(r) >= 0)
          ) {
            // console.log(
            //   "Rule #3 : If event.type and event.visibility were not empty, check both"
            // );
            return event;
          }

          // Rule #4: If event.visibility is not empty but the rest are empty, check visibility
          if (
            !voyager_type &&
            voyager_visibility &&
            voyager_visibility.length > 0 &&
            voyager_visibility.some(
              (r) => userMembershipType.indexOf(r) >= 0
            )
          ) {
            // console.log(
            //   "Rule #4: If event.visibility is not empty but the rest are empty, check visibility"
            // );
            return event;
          }
        }
      }
    }
  }
}



// All public event ticket are visible to both login and non-login
// All private ticket depends on visibility set
export const cefc_ticket_rule = (ticket, event, voyagerUser) => {
  const { membershipType, spouseOfLeader, roles } = voyagerUser;
  const userMembershipType = [membershipType];
  const userRoles = roles ? roles.map((role) => role.role) : [];
  const userTypes = roles ? roles.map((role) => role.type) : [];
  const userSubTypes = roles ? roles.map((role) => role.subtype) : [];

  if (ticket.meta === null) {
    // public ticket
    return ticket;
  } else {
    const {
      voyager_visibility,
      voyager_type,
      voyager_subtype,
      voyager_spouseofleader,
    } = ticket.meta.meta;

    // Rule #1 : If no ticket visibility is set, open for all private event ticket
    if ((spouseOfLeader === "Y") && (voyager_spouseofleader === "Y")) {
      // console.log("Rule #1 : If no ticket visibility is set, open for all private event ticket");
      return ticket;
    }

    // Rule #2 : If ticket.visibility is set, check user membershiptype
    if (
      !voyager_type && !voyager_subtype &&
      voyager_visibility && userMembershipType.length > 0 &&
      voyager_visibility.some((r) => userMembershipType.indexOf(r) >= 0)
    ) {
      // console.log("Rule #2 : If ticket.visibility is set, check user membershiptype");
      return ticket;
    }

    // Rule #3 : if ticket.visibility and ticket.type is set, check both membershiptype and type
    if (
      voyager_type && voyager_visibility &&
      userTypes.length > 0 && userMembershipType.length > 0 && (!voyager_subtype || !voyager_subtype.length) &&
      voyager_type.some((r) => userTypes.indexOf(r) >= 0) &&
      voyager_visibility.some((r) => userMembershipType.indexOf(r) >= 0)
    ) {
      // console.log("Rule #3 : if ticket.visibility and ticket.type is set, check both membershiptype and type");
      return ticket;
    }

    // Rule #4 : If ticket.subtype is set check ticket.type and ticket.visibility
    if (
      (voyager_visibility && voyager_type && voyager_subtype) &&
      (voyager_subtype.length > 0 && voyager_type.length > 0) &&
      voyager_subtype.some((r) => userSubTypes.indexOf(r) >= 0) &&
      voyager_type.some((r) => userTypes.indexOf(r) >= 0) &&
      voyager_visibility.some((r) => userMembershipType.indexOf(r) >= 0)
    ) {
      // console.log("Rule #4 : If ticket.subtype is set check ticket.type and ticket.visibility");
      return ticket;
    }


    if (voyager_visibility === null &&
      voyager_type === null &&
      voyager_subtype === null &&
      voyager_spouseofleader === "-" &&
      ticket.status === 'SALE'
    ) {
      return ticket;
    }

  }
}